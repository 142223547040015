.HowToJoinContainer {
  position: relative;
  background-color: #533884;
  font-family: "Nohemi";
  padding: 170px 0px 87px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color: white;
  margin-top: 2rem;
}
.TopIconContainer {
  position: absolute;
  top: -30px;
  width: 200px;
  height: 200px;
}

.TopIconContainer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: auto;
}

.HowToJoinContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 53px;
}

.HowToJoinHeading {
  font-size: 32px;
}
.HowToJoinDescription {
  font-size: 20px;
  width: 50%;
  text-align: center;
  line-height: 32px;
}

.HowToJoinButton {
  background-color: #eb4176;
  color: white;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 150px;
  height: 50px; */
  cursor: pointer;
  border: none;
  padding: 10px;
}

@media (max-width: 768px) {
  .HowToJoinContainer {
    padding: 78px 68px 51px 68px;
  }
  .HowToJoinContent {
    gap: 36px;
  }
  .TopIconContainer {
    position: absolute;
    top: -30px;
    width: 130px;
    height: 130px;
  }

  .HowToJoinHeading {
    font-size: 14px;
  }
  .HowToJoinDescription {
    width: 100%;
    font-size: 12px;
    line-height: 24px;
  }
}
