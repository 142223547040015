
  .circle-container {
    position: relative;
    width: 716px;
    height: 716px;

  }
  .ring {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 15px solid #5a2a82;
  }

  

  
  .circle-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .circle {
    padding: 5px;

    position: absolute;
    width: 345px;
    height: 346px;
    background: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 24.15px 53.78px 0px #0000001A; */

box-shadow: 0px 98.78px 98.78px 0px #00000017;

    font-size: 1.2em;
    font-weight: bold;
    color: #5a2a82;
    z-index: 999;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add transition for hover */
  }
  
  .circle:hover {
    /* transform: scale(1.1); Slightly enlarge on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhance shadow for hover */
    /* background: #f7f4fa; Subtle background color change */
  }
  
  .circle span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    background: #5a2a82;
    color: white;
    font-size: 24px;
    font-weight: bold;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add transition to inner circle */
  }
  
  .circle:hover span {
    right: 45%;
    font-size: 14px;
    position: relative;
    bottom: 50%; /* Initial position */
    transform: scale(1.2);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    transition: bottom 0.3s ease-in-out, transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.hover-content{
    color: #5a2a82;
    font-size: 20px;
    font-weight: 600;
    text-align: center;

}
.hover-content-desc{
    color: #1c2024;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin-top: 5px;
    font-family: inter;
}




  