.AmbassadorsContainer {
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.SomethingBigger {
  /* height: 405px; */
  background-color: #252153;
  padding: 104px 0px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
}

.SomethingBigger > .heading {
  font-size: 32px;
  color: #fff;
  font-family: "Nohemi";
  margin: 0;
  padding: 0;
}

.SomethingBigger > .description {
  font-size: 24px;
  color: #959595;
  text-align: center;
  width: 480px;
  line-height: 35px;
  margin: 0;
  padding: 0;
}

.WaitListButton {
  display: none;
}

.blog-content {
  padding-top: 180px;
  width: 100%;
  display: flex;
  gap: 20px;
  font-size: 20px;
  font-family: "Nohemi";
}

.blog-content-left {
  width: 50%;
  padding-left: 3rem;
}

.blog-content-left > .small-heading {
  font-size: 35px;
}
.blog-content-left > h3 {
  font-family: "Audiowide";
  color: #eb4176;
  font-size: 35px;
}
.blog-content-left > div > p {
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  font-family: "Inter";
  width: 298px;
  text-align: justify;
}
.blog-content-right {
  width: 50%;
}

.post-image {
  flex: 1;
  height: 400px;
  width: 497px;
  border-radius: 20px;
  background-color: #eee;
}
.explore-btn {
  background: #252153;
  width: Hug (204.32px)px;
  height: Hug (46.89px)px;
  border-radius: 8.5px;
  opacity: 0px;
  
}

.explore-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.adventure{
  font-family: Audiowide;
font-size: 25px;
font-weight: 400;
line-height: 30px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #fff;
text-transform: capitalize;
}
.hovermaincircle{
  display: flex;
  flex-direction: column;
}
.quest-flow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.quest-flow p {
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.05em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.below{
color:#533884
}
.below-cont{
  box-shadow: 0px 22px 49px 0px #0000001A;

box-shadow: 0px 90px 90px 0px #00000017;
background: var(--gray-50, #FFFFFF);

}


.quest-flow h3 {
  font-family: Audiowide;
  font-size: 35px;
  font-weight: 400;
  line-height: 36.75px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #533884;
}
.footer-background {
  background-image: url("../../images/custom-quest-subpage/background.svg");
  background-size: cover;
  background-position: right;
  z-index: 999;
  position: relative;
  left: 30%;
  bottom: 10%;
}


@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-scroll {
  display: flex;
  animation: scroll 20s linear infinite;
}



@media (max-width: 768px) {
  .AmbassadorsContainer {
    padding: 0 16px;
  }
  .SomethingBigger {
    padding: 50px 0px;
    gap: 30px;
  }
  .SomethingBigger > .heading {
    font-size: 20px;
  }

  .SomethingBigger > .description {
    font-size: 12px;
    width: 280px;
    line-height: 20px;
  }
  .WaitListButton {
    background-color: #eb4176;
    color: white;
    width: 110px;
    height: 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    cursor: pointer;
    transition: transform 0.5s ease;
  }
}
